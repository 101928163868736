
<div class="faa-header primary-dk-color" [style.background]="isDevelopmentEnv ? 'orange !important': '' ">

  <div class="header">
    <div class="container">

      <a class="siteLogo pull-left" href="http://www.faa.gov" style="margin-bottom:5px !important;">
        <img src="assets/logoFAA.png" alt="FAA seal" width="84" height="84" />

        <span style="margin-left:5px !important;">Federal Aviation Administration</span>
      </a>

      <ul class="topNav" role="menubar" style="padding-top:15px !important">
        <li role="presentation" style="padding-top: 2px" class="d-none d-sm-block">
          <a href="http://www.faa.gov" role="menuitem">FAA Home</a>
        </li>
        <li role="presentation" style="padding-top: 2px" class="d-none d-sm-block">
          <a href="/" role="menuitem">FOD | Home</a>
        </li>
        <li class="forYou" *ngIf="!(isAuthenticated | async) " role="presentation" aria-haspopup="true" style="padding-top: 2px">

          <button class="btn btn-sm" (click)="openLoginDialog('LOGIN')" id="loginLink" style="background:#937206; color: white!important;font-weight:bold; ">Login</button>


        </li>

        <li role="presentation" class="dropdown" *ngIf="(isAuthenticated | async)  ">

          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link" id="dropdownBasic1" ngbDropdownToggle>  <b class="d-inline"><small>Welcome </small> <i class="fas fa-user-circle"></i>  {{ userName | async}} </b></a>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="dropdown-item" (click)="openLoginDialog('CHANGE_PASSWORD')">Change Password</button>
              <button class="dropdown-item" (click)="onUserDialog('PROFILE')">Manage Profile</button>
              <button class="dropdown-item" (click)="onLogout()">Log Out</button>

            </div>
          </div>

        </li>


      </ul>

    </div>
  </div>

</div>

<nav class="navbar navbar-expand-lg navbar-dark primary-color">

  <button class="navbar-toggler primary-color" type="button"
          (click)="toggleNavbar()">
    <span class="navbar-toggler-icon "></span>
  </button>


  <div class="container">
    <div class="collapse navbar-collapse"
         [ngClass]="{ 'show': navbarOpen }">

      <ul class="navbar-nav mr-auto">

        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/home" class="nav-link">Home</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/submitreport" class="nav-link">Submit a Report</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a routerLink="/editreport" class="nav-link">Edit a Report</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="(isAuthenticated | async)  ">
          <a routerLink="/searchreport" class="nav-link">Search a Report</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             href="https://www.faa.gov/airports/airport_safety/fod"
             target="_blank">FOD Resources</a>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="(isAuthenticated | async) && role === 'ADMIN'">
          <a [routerLink]="'/manageUser'" class="nav-link" title="Manage Users">Manage Users</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="(isAuthenticated | async)  ">
          <div ngbDropdown class="d-inline-block">
            <a class="btn btn-link dropdown-root" id="" ngbDropdownToggle>
              Documentation
            </a>

            <div ngbDropdownMenu aria-labelledby="ddlDoc">
              <a class="dropdown-item a-color-black"
                 href="https://qa-fod.faa.gov/swagger/index.html" target="_blank" rel="noopener noreferrer">
                API
              </a>
              <a class="dropdown-item a-color-black" href="https://qa-fod.faa.gov/documentation/index.html" target="_blank" rel="noopener noreferrer">
                Frontend
              </a>

            </div>
          </div>
        </li>
      </ul>


    </div>
  </div>
</nav>
